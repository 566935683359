import * as React from 'react';
import SingleColumnLayout from '../layouts/singleColumnLayout/SingleColumnLayout';
import Img, {FluidObject} from 'gatsby-image';
import {GridDensity} from '../components/soul/layout/grid/GridDensity';
import GridItem from '../components/soul/layout/grid/GridItem';
import {GridItemSize} from '../components/soul/layout/grid/GridItemSize';
import Grid from '../components/soul/layout/grid/Grid';
import {graphql} from 'gatsby';


/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
interface LocationPageProps {
    data: {
        file: {
            childLocationJson: {
                title: string,
                description: string[],
                image: {
                    childImageSharp: {
                        fluid: FluidObject
                    }
                },
                instructions: {
                    title: string,
                    description: string,
                    how_to: {
                        title: string,
                        description: string,
                        image: {
                            childImageSharp: {
                                fluid: FluidObject
                            }
                        }
                    },
                    reference: {
                        title: string,
                        links: [
                            {
                                label: string,
                                href: string
                            },
                            {
                                'label': string,
                                'href': string
                            }
                        ],
                        numbers: [
                            {
                                label: string,
                                value: string
                            }
                        ]
                    },
                    map: string
                },
                contact_us: {
                    title: string,
                    subtitle: string,
                    contact: string,
                    email: string
                }
            }
        }
    }
}

const LocationPage: React.FC<LocationPageProps> = ({data}) => {
    const locationData = data.file.childLocationJson;

    return (
        <SingleColumnLayout>
            <div className="centered-column">
                <div className="soul-space-stack-bottom-2-xl">
                    <Grid density={GridDensity.WIDE} additionalClasses="soul-space-stack-bottom-m">
                        <GridItem size={GridItemSize.ONE_HALF} additionalClasses='l-palm--one-whole'>
                            <h1 className="page-title">{locationData.title}</h1>
                        </GridItem>
                        <GridItem size={GridItemSize.ONE_HALF} additionalClasses='display--desktop'/>
                    </Grid>
                    <Grid density={GridDensity.WIDE} mobileInverse={true}>
                        <GridItem size={GridItemSize.ONE_HALF} additionalClasses='l-palm--one-whole'>
                            <Img fluid={locationData.image.childImageSharp.fluid} alt={locationData.title}/>
                        </GridItem>
                        <GridItem size={GridItemSize.ONE_HALF} additionalClasses='l-palm--one-whole soul-content soul-content--m soul-space-stack-bottom-m'>
                            {locationData.description.map(paragraph =>
                                <p key={paragraph}>{paragraph}</p>
                            )}
                        </GridItem>
                    </Grid>
                </div>
                <div className="ew-divider"/>
                <div className="soul-space-stack-bottom-2-xl soul-content soul-content--m">
                    <Grid density={GridDensity.WIDE}>
                        <GridItem size={GridItemSize.ONE_HALF} additionalClasses='l-palm--one-whole'>
                            <h2>{locationData.instructions.title}</h2>
                            <p>{locationData.instructions.description}</p>
                        </GridItem>
                        <GridItem size={GridItemSize.ONE_HALF} additionalClasses='l-palm--one-whole'>
                            <h3>{locationData.instructions.reference.title}</h3>
                            {locationData.instructions.reference.links.map(link =>
                                <p key={link.href}><a href={link.href} target="_blank">{link.label}</a></p>
                            )}
                            {locationData.instructions.reference.numbers.map(number =>
                                <p key={number.value}><a href={`tel:${number.value}`}>{number.label} - {number.value}</a></p>
                            )}
                        </GridItem>
                        <GridItem size={GridItemSize.ONE_HALF} additionalClasses='l-palm--one-whole'>
                            <h3>{locationData.instructions.how_to.title}</h3>
                        </GridItem>
                        <GridItem size={GridItemSize.ONE_HALF} additionalClasses='display--desktop'/>
                        <GridItem size={GridItemSize.ONE_HALF} additionalClasses='l-palm--one-whole'>
                            <p>{locationData.instructions.how_to.description}</p>
                        </GridItem>
                        <GridItem size={GridItemSize.ONE_HALF} additionalClasses='l-palm--one-whole'>
                            <Img fluid={locationData.instructions.how_to.image.childImageSharp.fluid}
                                 alt={locationData.instructions.how_to.title}/>
                        </GridItem>
                    </Grid>
                </div>
                <iframe src={locationData.instructions.map}
                        width="100%" height="450px" frameBorder={0} allowFullScreen aria-hidden={false} tabIndex={0}>
                </iframe>
                <div className="soul-space-stack-bottom-2-xl soul-content soul-content--m">
                    <h2 className="soul-space-stack-top-l">{locationData.contact_us.title}</h2>
                    <p>
                        {locationData.contact_us.subtitle}<br/>
                        {locationData.contact_us.contact}&nbsp;
                        <a href={`mailto:${locationData.contact_us.email}`}>
                            <strong>{locationData.contact_us.email}</strong>
                        </a>
                    </p>
                </div>
            </div>
        </SingleColumnLayout>
    );
};

export const pageQuery = graphql`
    query RetrieveLocationPageContent {
        file(relativeDirectory: {eq: "location"}, name: {eq: "location-content"}) {
            childLocationJson {
                title
                description
                image {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                instructions {
                    title
                    description
                    how_to {
                        title
                        description
                        image {
                            childImageSharp {
                                fluid {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    reference {
                        title
                        links {
                            label
                            href
                        }
                        numbers {
                            label
                            value
                        }
                    }
                    map
                }
                contact_us {
                    title
                    subtitle
                    contact
                    email
                }
            }
        }
    }
`;

export default LocationPage;
